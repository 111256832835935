export const types = {
    GET_PARENTS: 'GET_PARENTS',
    GET_PRODUCTS: 'GET_PRODUCTS',
}

export const getParents = (data) => {
    return {
        type: types.GET_PARENTS,
        payload: data
    }
}

export const getProducts = (data) => {
    return {
        type: types.GET_PRODUCTS,
        payload: data
    }
}