import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//rest are the props inherit from parent component
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      //Protect admin page of regular user
      if (rest.isAuthenticated && !rest.isStaff && rest.path === '/admin') {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
      return (
        rest.isAuthenticated
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }}
    />
  )
}

export default PrivateRoute
