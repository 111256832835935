import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tag, Button, Table } from "antd";

import axios from "axios";
import { connect } from "react-redux";
import useForm from "react-hook-form";
import history from "../_helpers/history";
import moment from "moment-timezone";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { auth } from "../_redux/actions/";
import { BASE_URL, REPORT, TIMEZONE } from "../_helpers/constants";

const columns = [
  {
    title: "Fecha de canje",
    dataIndex: "date",
    key: "date",
    render: (date) => moment(date).tz(TIMEZONE).format("DD/MM/YYYY"),
    sorter: (a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "Código",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Cupón",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Descripción",
    dataIndex: "desc",
    key: "desc",
  },
  {
    title: "Tienda",
    dataIndex: "store",
    key: "store",
  },
  // {
  //   title: "Vendedor",
  //   dataIndex: "salesman",
  //   key: "salesman",
  // },
  {
    title: "Cliente",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "DUI",
    dataIndex: "dui",
    key: "dui",
  },
  {
    title: "Colegio",
    dataIndex: "student",
    key: "student.colegio",
    render: (student) => JSON.parse(student)[0].colegio,
  },
  {
    title: "Alumno/a",
    dataIndex: "student",
    key: "student.nombre",
    render: (student) => JSON.parse(student)[0].nombre,
  },
  {
    title: "Grado",
    dataIndex: "student",
    key: "student.grado",
    render: (student) => JSON.parse(student)[0].grado,
  },
  {
    title: "Sección",
    dataIndex: "student",
    key: "student.seccion",
    render: (student) => JSON.parse(student)[0].seccion,
  },
];

const Report = (props) => {
  const [coupon, setCoupon] = useState({});
  const [reportData, setReportData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [requestData, setRequestData] = useState(false);
  const [tableBody, setTableBody] = useState([]);

  const goToHome = () => {
    history.push("/");
  };

  const closeSession = () => {
    props.logOut();
  };

  const getReport = () => {
    setLoadingData(true);
    let axiosOptions = {
      method: "post",
      url: BASE_URL + REPORT,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: coupon,
    };

    axios(axiosOptions)
      .then((response) => {
        let data = [];
        let body = [];
        response.data.codes.forEach((item) => {
          data.push(item);
          //console.log(item.student);
          let student = JSON.parse(item.student)[0];
          //Report to export
          body.push(
            <tr key={item.key}>
              <td>{moment(item.date).tz(TIMEZONE).format("DD/MM/YYYY")}</td>
              <td>{item.code}</td>
              <td>{item.name}</td>
              <td>{item.desc}</td>
              <td>{item.store}</td>
              <td>{item.customer}</td>
              <td>{item.email}</td>
              <td>{item.dui}</td>
              <td>{student.colegio}</td>
              <td>{student.nombre}</td>
              <td>{student.grado}</td>
              <td>{student.seccion}</td>
            </tr>
          );
        });

        setTableBody(body);
        setReportData(data);
        setLoadingData(false);
        setRequestData(true);
      })
      .catch(function (error) {
        console.log(error);
        setLoadingData(false);
      });
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (reportData.length === 0 && !requestData) {
      if (props.role !== "manager") history.push("/");
      getReport();
    }
  }, [reportData]);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="h-100 background"
    >
      <Col xs={22}>
        <Card
          style={{ width: "100%" }}
          bordered={false}
          className="layout-card"
          title={
            <>
              <Tag className="mt-15 ml-10" color="purple">
                {`${props.username} en ${props.store}`}
              </Tag>
              <div style={{ float: "right" }}>
                <Button
                  className="mr-10 mt-5"
                  type="primary"
                  shape="round"
                  icon="home"
                  size="large"
                  onClick={goToHome}
                />
                <Button
                  className="mr-10 mt-5"
                  type="danger"
                  shape="round"
                  icon="logout"
                  size="large"
                  onClick={closeSession}
                />
              </div>
            </>
          }
        >
          <Row type="flex" justify="center" align="middle">
            <Col span={22}>
              {/* ***************************** EXPORT REPORT AS EXCEL ***************************** */}
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename={`Reporte_${moment(new Date())
                  .tz(TIMEZONE)
                  .format("DD-MM-YYYY_HH-mm")}`}
                sheet="tablexls"
                buttonText="Descargar como XLS"
              />
              <table id="table-to-xls" style={{ display: "none" }}>
                <thead>
                  <tr>
                    <th>Fecha de canje</th>
                    <th>Código</th>
                    <th>Cupón</th>
                    <th>Descripción</th>
                    <th>Tienda</th>
                    <th>Cliente</th>
                    <th>E-mail</th>
                    <th>DUI</th>
                    <th>Colegio</th>
                    <th>Alumno/a</th>
                    <th>Grado</th>
                    <th>Sección</th>
                  </tr>
                </thead>
                <tbody>{tableBody}</tbody>
              </table>
              {/* ***************************** EXPORT REPORT AS EXCEL ***************************** */}
              <Table
                title={() => (
                  <div style={{ textAlign: "center" }}>
                    <strong>Listado de cupones canjeados</strong>
                  </div>
                )}
                loading={loadingData}
                columns={columns}
                dataSource={reportData}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: 5,
                  locale: { items_per_page: "/ página" },
                  pageSizeOptions: ["5", "10", "25"],
                  align: "center",
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    role: state.auth.role,
    store: state.auth.store,
    token: state.auth.access_token,
  };
};

const mapDispatchToProps = {
  logIn: auth.logIn,
  logOut: auth.logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);
