import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//rest are the props inherit from parent component
const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      return (
        rest.isAuthenticated
          ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          : <Component {...props} />
      )
    }}
    />
  )
}

export default LoginRoute
