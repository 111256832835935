import { auth, data } from '../actions/'

let initialState = {
    auth: {},
    isAuthenticated: false,
    parents: [],
    products: []
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case auth.types.LOGGED_IN:
            return {
                ...state,
                auth: action.payload.data,
                isAuthenticated: true
            }
        case auth.types.LOGGED_OUT:
            return {
                ...state,
                auth: {},
                isAuthenticated: false,
                parents: [],
                products: []
            }
        case data.types.GET_PARENTS:
            return {
                ...state,
                parents: action.payload
            }
        case data.types.GET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        default:
            return state
    }
}
export default rootReducer