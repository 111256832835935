import React, { useState } from "react";
import { Card, Row, Col, Alert, Tag, Button, notification } from "antd";

import Lottie from "lottie-react-web";
import axios from "axios";
import { connect } from "react-redux";
import useForm from "react-hook-form";
import history from "../_helpers/history";
import moment from "moment-timezone";

import animation from "../_animations/barcode_scanner";
import logo from "../_images/ese-plus-logo.png";
import { auth } from "../_redux/actions/";
import {
  BASE_URL,
  TIMEZONE,
  REVIEW_CODE,
  REDEEM_CODE,
} from "../_helpers/constants";

const Home = (props) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [coupon, setCoupon] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: false });
  const [reviewed, setReviewed] = useState(false);

  const goToReport = () => {
    history.push("/report/");
  };

  const closeSession = () => {
    props.logOut();
  };

  const enterLoading = () => {
    setIsLoading({ ...isLoading, loading: true });
  };

  const outLoading = () => {
    setIsLoading({ ...isLoading, loading: false });
  };

  const redeemCode = (data) => {
    enterLoading();
    let copy_coupon = coupon;
    copy_coupon.student = JSON.stringify(coupon.student);
    let axiosOptions = {
      method: "post",
      url: BASE_URL + REDEEM_CODE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },

      data: {
        coupon: copy_coupon,
        name: data.name,
        lastname: data.lastname,
        dui: data.dui,
        email: data.email,
      },
    };

    axios(axiosOptions)
      .then((response) => {
        outLoading();
        console.log(response.data);
        let msg = "Cupón validado con éxito";

        notification.success({
          message: "Notificación",
          description: msg,
          duration: 0,
          style: {
            width: 400,
            marginLeft: 335 - 400,
          },
        });

        resetForm();
      })
      .catch(function (error) {
        let title = "Algo salió mal";
        let msg =
          "Algo salión durante el proceso, por favor revise los datos ingresados e intente de nuevo.";

        if (error.response.status === 400) {
          title = "Código inválido";
          msg = "El código ingresado ya fue canjeado.";
        }

        if (error.response.status === 401) {
          title = "Cerrando sesión";
          msg = "Su sesión ha expirado o su token no es válido.";
          closeSession();
        }

        if (error.response.status === 404) {
          title = "Código inválido";
          msg = "El código ingresado no esta registrado.";
        }

        outLoading();
        notification.error({
          message: title,
          description: msg,
          duration: 0,
          style: {
            width: 400,
            marginLeft: 335 - 400,
          },
        });
      });
  };

  const onSubmit = (data) => {
    enterLoading();

    let axiosOptions = {
      method: "post",
      url: BASE_URL + REVIEW_CODE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      data: {
        code: data.code,
      },
    };

    axios(axiosOptions)
      .then((response) => {
        outLoading();
        let msg = (
          <>
            <p>
              Cliente:{" "}
              <strong>{` ${
                response.data.parent[0].responsable
                  ? response.data.parent[0].responsable
                  : "No disponible"
              } `}</strong>
              <br /> DUI:{" "}
              <strong>{` ${
                response.data.parent[0].dui_responsable
                  ? response.data.parent[0].dui_responsable
                  : "No disponible"
              }`}</strong>
            </p>
            <hr style={{ margin: "0 !important" }} />
            <p>
              Colegio: <strong>{` ${response.data.student[0].colegio}`}</strong>
              <br /> Grado:{" "}
              <strong>{` ${response.data.student[0].grado}`}</strong>
              <br /> Sección:{" "}
              <strong>{` ${response.data.student[0].seccion}`}</strong>
              <br /> Alumno/a:{" "}
              <strong>{` ${response.data.student[0].nombre}`}</strong>
            </p>
            <hr style={{ margin: "0 !important" }} />
            <p>
              Fecha de inicio:{" "}
              <strong>{` ${response.data.coupon[0].fecha_inicio}`}</strong>
              <br /> Fecha de finalización:{" "}
              <strong>{` ${response.data.coupon[0].fecha_fin}`}</strong>
              <br /> Producto:{" "}
              <strong>{` ${response.data.coupon[0].nombreProducto}`}</strong>
              <br /> Descripción:{" "}
              <strong>{` ${response.data.coupon[0].descripcion}`}</strong>
            </p>
            {response.data.validated && (
              <>
                <hr style={{ margin: "0 !important" }} />
                <strong style={{ color: "red" }}>Cupón ya canjeado</strong>
                <p>
                  Tienda:{" "}
                  <strong>{` ${response.data.redeem_data.store}`}</strong>
                  <br /> Cliente:{" "}
                  <strong>{` ${response.data.redeem_data.customer}`}</strong>
                  <br /> E-mail:{" "}
                  <strong>{` ${response.data.redeem_data.email}`}</strong>
                  <br /> DUI:{" "}
                  <strong>{` ${response.data.redeem_data.dui}`}</strong>
                  <br /> Fecha de canje:{" "}
                  <strong>{` ${moment(response.data.redeem_data.date)
                    .tz(TIMEZONE)
                    .format("DD/MM/YYYY")}`}</strong>
                </p>
              </>
            )}
          </>
        );

        notification.success({
          message: "Información del cupón",
          description: msg,
          duration: 0,
          style: {
            width: 400,
            marginLeft: 335 - 400,
          },
        });

        setCoupon(response.data);
        setReviewed(true);
      })
      .catch(function (error) {
        console.log(error);
        let title = "Algo salió mal";
        let msg =
          "Algo salión durante el proceso, por favor los datos e intente de nuevo.";

        if (error.response.status === 400) {
          title = "Código inválido";
          msg = "El código ingresado ya fue canjeado.";
        } else if (error.response.status === 401) {
          title = "Cerrando sesión";
          msg = "Su sesión ha expirado o su token no es válido.";
          closeSession();
        } else if (error.response.status === 404) {
          title = "Código inválido";
          msg = "El código ingresado no esta registrado.";
        } else if (error.response.status === 500) {
          title = "Error de comunicación";
          msg =
            "Ha ocurrido un error de comunicación con los servicios internos, intente nuevamente.";
        } else {
          title = "Error de comunicación";
          msg =
            "Ha ocurrido un error de comunicación con los servicios internos, intente nuevamente.";
        }

        outLoading();
        notification.error({
          message: title,
          description: msg,
          duration: 0,
          style: {
            width: 400,
            marginLeft: 335 - 400,
          },
        });
      });
  };

  const resetForm = () => {
    reset();
    setReviewed(false);
  };

  /*********************** handle clases errors style  ***********************/
  let errorInput = "ant-input ant-input-lg mb-10 input-error mt-10";
  let normalInput = "ant-input ant-input-lg mb-10";

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="h-100 background"
    >
      <Col xs={20} sm={18} md={16} lg={12} xl={10} xxl={8}>
        <Card
          style={{ width: "100%" }}
          bordered={false}
          className="layout-card"
          title={
            <>
              <Tag
                className="mt-15 ml-10"
                color="purple"
              >{`${props.username} en ${props.store}`}</Tag>
              <div style={{ float: "right" }}>
                {props.role === "manager" && (
                  <Button
                    className="mr-10 mt-5"
                    type="primary"
                    shape="round"
                    icon="file"
                    size="large"
                    onClick={goToReport}
                  />
                )}

                <Button
                  className="mr-10 mt-5"
                  type="danger"
                  shape="round"
                  icon="logout"
                  size="large"
                  onClick={closeSession}
                />
              </div>
            </>
          }
        >
          <Row type="flex" justify="center" align="middle">
            <Col span={22}>
              <div style={{ width: "25%", height: "25%", margin: "0 auto" }}>
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                />
              </div>
            </Col>
            <Col span={22}>
              {/* Form to review info code */}
              {!reviewed && (
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  {errors.code && errors.code.type === "required" && (
                    <Alert
                      className="input-error-text"
                      message="Campo requerido, por favor ingrese un código válido."
                      type="error"
                      showIcon
                    />
                  )}
                  {errors.code && errors.code.type === "maxLength" && (
                    <Alert
                      className="input-error-text"
                      message="Código inválido, texto demasiado largo."
                      type="error"
                      showIcon
                    />
                  )}
                  <input
                    type="text"
                    disabled={reviewed}
                    className={errors.code ? errorInput : normalInput}
                    placeholder="Introduzca el código"
                    name="code"
                    ref={register({ required: true, maxLength: 12 })}
                  />
                  <Row type="flex" justify="space-between">
                    <Col span={21}>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading.loading}
                        block
                      >
                        Revisar código
                      </Button>
                    </Col>

                    <Col span={3}>
                      <Button
                        style={{ float: "right" }}
                        size="large"
                        type="default"
                        icon="delete"
                        onClick={resetForm}
                        block
                      />
                    </Col>
                  </Row>
                </form>
              )}

              {/* Form to redeem code */}
              {reviewed && (
                <form onSubmit={handleSubmit(redeemCode)} autoComplete="off">
                  <h3>
                    Código: <strong>{coupon.codigo}</strong>
                  </h3>
                  {errors.name && errors.name.type === "required" && (
                    <Alert
                      className="input-error-text"
                      message="Campo requerido, por favor ingrese los nombres del responsable."
                      type="error"
                      showIcon
                    />
                  )}
                  {errors.name && errors.name.type === "maxLength" && (
                    <Alert
                      className="input-error-text"
                      message="Texto demasiado largo, introduzca un nombre más corto"
                      type="error"
                      showIcon
                    />
                  )}
                  <input
                    type="text"
                    className={errors.name ? errorInput : normalInput}
                    placeholder="Introduzca los nombres"
                    name="name"
                    ref={register({
                      required: true,
                      maxLength: 50,
                    })}
                  />
                  {errors.lastname && errors.lastname.type === "required" && (
                    <Alert
                      className="input-error-text"
                      message="Campo requerido, por favor ingrese los apellidos del responsable."
                      type="error"
                      showIcon
                    />
                  )}
                  {errors.lastname && errors.lastname.type === "maxLength" && (
                    <Alert
                      className="input-error-text"
                      message="Texto demasiado largo, introduzca un apellido más corto"
                      type="error"
                      showIcon
                    />
                  )}
                  <input
                    type="text"
                    className={errors.lastname ? errorInput : normalInput}
                    placeholder="Introduzca los apellidos"
                    name="lastname"
                    ref={register({ required: true, maxLength: 50 })}
                  />

                  {errors.email && errors.email.type === "required" && (
                    <Alert
                      className="input-error-text"
                      message="Campo requerido, por favor ingrese el email."
                      type="error"
                      showIcon
                    />
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Alert
                      className="input-error-text"
                      message="Ingrese un email válido"
                      type="error"
                      showIcon
                    />
                  )}
                  <input
                    type="text"
                    className={errors.email ? errorInput : normalInput}
                    placeholder="Introduzca el email"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/,
                    })}
                  />

                  {errors.dui && errors.dui.type === "required" && (
                    <Alert
                      className="input-error-text"
                      message="Campo requerido, por favor ingrese el DUI del responsable."
                      type="error"
                      showIcon
                    />
                  )}
                  {errors.dui && errors.dui.type === "pattern" && (
                    <Alert
                      className="input-error-text"
                      message="El DUI ingresado no cumple con el patrón."
                      type="error"
                      showIcon
                    />
                  )}
                  <input
                    type="text"
                    className={errors.dui ? errorInput : normalInput}
                    placeholder="Introduzca el número de DUI"
                    name="dui"
                    ref={register({
                      required: true,
                      pattern: /^[0-9]{8}\-[0-9]{1}?$/,
                    })}
                  />

                  <Row type="flex" justify="space-between">
                    <Col span={21}>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading.loading}
                        block
                      >
                        Canjear código
                      </Button>
                    </Col>

                    <Col span={3}>
                      <Button
                        style={{ float: "right" }}
                        size="large"
                        type="default"
                        icon="delete"
                        onClick={resetForm}
                        block
                      />
                    </Col>
                  </Row>
                </form>
              )}
            </Col>
          </Row>

          {/* Formulario de redención del código */}
        </Card>
      </Col>
      <a target="_blank" rel="noopener noreferrer" href="https://ese.plus">
        <img className="floating-brand" src={logo} alt="logo" />
      </a>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    store: state.auth.store,
    token: state.auth.access_token,
    role: state.auth.role,
  };
};

const mapDispatchToProps = {
  logIn: auth.logIn,
  logOut: auth.logOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
