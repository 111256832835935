export const types = {
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT',
}

export const logIn = (data) => {
    return {
        type: types.LOGGED_IN,
        payload: data
    }
}

export const logOut = () => {
    return {
        type: types.LOGGED_OUT
    }
}