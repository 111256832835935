import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ThunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer'

const persistConfig = {
    key: 'main',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const configureStore = () => {
    let store = createStore(persistedReducer,
        compose(
            applyMiddleware(ThunkMiddleware),//logger
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() || compose
        )
    )
    let persistor = persistStore(store)
    return { store, persistor }
}

export default configureStore