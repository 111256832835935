import React from "react";
import { Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import LoginRoute from "./_routes/LoginRoute";
import PrivateRoute from "./_routes/PrivateRoute";
import Login from "./components/Login";
import Home from "./components/Home";
import Report from "./components/Report";
import history from "./_helpers/history";

import "./App.css";

const App = (props) => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute
          exact
          path="/report"
          isAuthenticated={props.isAuthenticated}
          component={Report}
          isStaff={props.isStaff}
        />
        <PrivateRoute
          exact
          path="/"
          isAuthenticated={props.isAuthenticated}
          component={Home}
          isStaff={props.isStaff}
        />
        <LoginRoute
          exact
          path="/login"
          isAuthenticated={props.isAuthenticated}
          component={Login}
        />

        {/* Default routing*/}
        <PrivateRoute
          isAuthenticated={props.isAuthenticated}
          component={Home}
          isStaff={props.isStaff}
        />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
    isStaff: state.auth.is_staff,
  };
};
export default connect(mapStateToProps)(App);
