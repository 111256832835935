import React, { useState } from 'react';
import { Card, Row, Col, Input, Alert, Icon, Button, notification } from 'antd'

import Lottie from 'lottie-react-web'
import Swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';

import animation from '../_animations/lock_animation'
import logo from '../_images/ese-plus-logo.png'
import useForm from './useForm/useForm';
import validate from './useForm/LoginValidationRules';
import { BASE_URL } from '../_helpers/constants'
import history  from '../_helpers/history'
import { errorMessage } from '../_helpers/axiosErrorsHandler'
import { auth } from '../_redux/actions/'


const Login = (props) => {
    const [isLoading, setIsLoading] = useState({ loading: false })

    const enterLoading = () => {
        setIsLoading({ ...isLoading, loading: true});
    };

    const outLoading = () => {
        setIsLoading({ ...isLoading, loading: false });
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit
    } = useForm(login, validate)

    function login() {
        //console.log('No errors, submit callback called!');
        enterLoading()
        let axiosOptions = {
            method: 'post',
            url: `${BASE_URL}api/v1/token/`,
            headers: { "Content-Type": "application/json" },
            data: {
                username: values.username,
                password: values.password,
                application: 'main'
            }
        }
        axios(axiosOptions)
            .then(response => {
                // Save into redux
                props.logIn(response)
                outLoading()

                notification.success({
                    message: 'Éxito',
                    description:
                        'Sesión iniciada de manera exitosa.',
                    style: {
                        width: 400,
                        marginLeft: 335 - 400,
                    },
                })
                history.push('/')
                
            })
            .catch(error => {
                let err = errorMessage(error);
                outLoading()
                if (err.error) {
                    //error from exceptions
                    err = err.error
                } else if (err.status === 0) {
                    err = 'Check internet connection'
                } else {
                    console.log(err)
                    //error from invalid data
                    err = JSON.parse(err.data)
                    err = err.error_description
                }
                notification.error({
                    message: 'Algo salió mal',
                    description: 'Revisa tu conexión a intenet y tus credenciales e intenta de nuevo, si el problema persiste consulta a soporte tecnico a soporte@ese.plus',
                    duration: 0,
                    style: {
                        width: 400,
                        marginLeft: 335 - 400,
                    },
                })
            })
    }


    return (
        <Row type="flex" justify="center" align="middle" className="h-100 background">
            <Col xs={20} sm={18} md={16} lg={12} xl={10} xxl={8}>
                <Card style={{ width: '100%' }} className="t-50 layout-login-card">
                    <Row type="flex" justify="center" align="middle">
                        <Col span={22}>
                            <div style={{ width: "50%", height: "50%", margin: "0 auto", marginTop: "-50px", marginBottom: "-50px" }}>
                                <Lottie
                                    options={{
                                        animationData: animation
                                    }}

                                />
                            </div>
                        </Col>
                        <Col span={22}>
                            <FormLogin
                                errors={errors}
                                values={values}
                                onSubmitHandler={handleSubmit}
                                onChangeHandler={handleChange}
                                isLoading={isLoading.loading}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <a target="_blank" rel="noopener noreferrer" href="https://ese.plus">
                <img className="floating-brand" src={logo} alt="logo" />
            </a>
        </Row>
    )
}

const FormLogin = (props) => {
    return (
        <Row type="flex" justify="center" align="middle">
            <form className="" autoComplete="off">
                <Col span={24} className="mb-20" >
                    <Input
                        placeholder="Ingrese su usuario"
                        name="username"
                        value={props.values.username || ""}
                        onChange={props.onChangeHandler}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}

                    />
                    {
                        props.errors.username &&
                        <Alert message={`${props.errors.username}`} type="error" showIcon className="red-alert" />
                    }
                </Col>
                <Col span={24} className="mb-20">
                    <Input.Password
                        className={`${props.errors.password && 'alert-validate'}`}
                        data-validate="Password is required"
                        placeholder="Ingrese su contraseña"
                        name="password"
                        value={props.values.password || ""}
                        onChange={props.onChangeHandler}
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    />
                    {
                        props.errors.password &&
                        <Alert message={`${props.errors.password}`} type="error" showIcon className="red-alert" />
                    }
                </Col>
                <Col span={24} className="mb-10 center">
                    <Button
                        size="large"
                        type="primary"
                        icon="right"
                        loading={props.isLoading}
                        htmlType="submit"
                        onClick={(e) => { props.onSubmitHandler(e); }}>
                        Iniciar sesión
                </Button>
                </Col>
            </form>
        </Row>
    )
}

const mapDispatchToProps = {
    logIn: auth.logIn
};

export default connect(null, mapDispatchToProps)(Login)